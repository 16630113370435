import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/app/node_modules/@interness/web-core/src/templates/mdx/default.js";
import CallToAction from '@interness/web-core/src/components/modules/CallToAction/CallToAction';
import HeaderMedia from '@interness/theme-default/src/components/HeaderMedia';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const HeaderImage = makeShortcode("HeaderImage");
const Wrapper = makeShortcode("Wrapper");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <hr></hr>
    <HeaderImage mdxType="HeaderImage">
      <HeaderMedia id='nahrung' mdxType="HeaderMedia" />
    </HeaderImage>
    <Wrapper mdxType="Wrapper">
      <hr></hr>
      <h1>{`Ernährungsberatung`}</h1>
      <p>{`Die beste Ernährung für Gewicht und Gesundheit Wie heißt es so schön: Gut essen und trinken hält Leib und Seele zusammen. Eine sehr treffende Aussage - sofern das Gut qualitativ und nicht quantitativ interpretiert wird.`}</p>
      <p>{`Eine ausgewogene und gesunde Ernährung ist grundlegend für Ihr Wohlbefinden und den Erhalt Ihrer Gesundheit. Sie ist Basis für Vitalität und Aktivität und somit ein Schlüssel zu mehr Lebensqualität.`}</p>
      <p>{`Gemeinsam mit Ihnen bringen wir Ihre Ernährung und Ihre Gesundheit ins Gleichgewicht - bei Über- oder Untergewicht als auch bei Unverträglichkeiten.`}</p>
      <p>{`Ernährungsberatung ist meist keine Leistung der gesetzlichen Krankenversicherung. Manche Krankenkassen erstatten einen Teil der anfallenden Kosten. Sprechen Sie mit Ihrer Krankenkasse.`}</p>
      <hr></hr>
      <div style={{
        textAlign: 'center'
      }}>
  <CallToAction mdxType="CallToAction" />
      </div>
      <hr></hr>
    </Wrapper>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      